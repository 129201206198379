<template>
  <div class="container">
    <ToeflMenu active="6" />
    <div class="toefl-header">
      <PageTitle>
        {{ $t('toefl.Promotion Code Record')}}
      </PageTitle>
    </div>
    <hr />
    <div class="text-center">
      <el-pagination
        @current-change="setPage"
        :page-size="recordsPerPage"
        :current-page="recordsPage"
        :total="recordsTotal"
        :pager-count="5"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table :data="codeRecordsData" style="width: 100%">
      <el-table-column label="Name" width="180">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: scope.row.order.user.id }
            }"
          >
            {{
              userMethods.displayName(
                scope.row.order.user.first_name,
                scope.row.order.user.last_name
              )
            }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="promotion_code" label="Code" width="180">
      </el-table-column>
      <el-table-column label="Referrer">
        <template slot-scope="scope">
          <span v-if="scope.row.order.user.referral_user">
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.order.user.referral_user.id }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.order.user.referral_user.first_name,
                  scope.row.order.user.referral_user.last_name
                )
              }}
            </router-link>
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="Price">
        <template slot-scope="scope">
          <el-tag type="success">
            <b>{{ scope.row.order.package.stripe_usd_price }} USD</b>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Discount">
        <template slot-scope="scope">
          <el-tag type="warning">
            <b> -{{ scope.row.discount_price }} {{ scope.row.price_currency }}</b>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Spend">
        <template slot-scope="scope">
          <el-tag type="success" effect="dark">
            <b> {{ scope.row.order.price }} {{ scope.row.order.price_currency }}</b>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Date">
        <template slot-scope="scope">
          {{ scope.row.transaction_date }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-4">
      <el-pagination
        @current-change="setPage"
        :page-size="recordsPerPage"
        :current-page="recordsPage"
        :total="recordsTotal"
        :pager-count="5"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import { instant, user } from "@ivy-way/material";
import User from "@/apis/user.js";
import ToeflMenu from "@/views/toefl/components/ToeflMenu.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  metaInfo() {
    return {
      title: "Balance - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    ToeflMenu
  },
  data() {
    return {
      codeRecordsData: [],
      recordsPerPage: 0,
      recordsTotal: 0,
      recordsPage: 1
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  mounted() {
    this.getPromotionCodeRecords();
  },
  methods: {
    async getPromotionCodeRecords(page) {
      const res = await User.getPromotionCodeRecords({
        page: page
      });
      this.codeRecordsData = res.promotionCodeRecords.data;
      this.recordsPerPage = res.promotionCodeRecords.per_page;
      this.recordsTotal = res.promotionCodeRecords.total;
    },
    setPage(page) {
      this.page = page;
      this.getPromotionCodeRecords(page);
    }
  }
};
</script>

<style scoped>
.toefl-header {
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
